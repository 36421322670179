
body.mobile-nav-show {
    nav.navbar {
      opacity: 1;
      visibility: visible;
      transform: translate3d(-400px, 0, 0);
      @include media-breakpoint-down(xs) {
        & {
          transform: translate3d(-100%, 0, 0) !important;
        }
      }
    }
  }
  
  nav.navbar:not(.desktop-navbar) {
    .menu-toggle {
      i {
        transition: .3s ease;
      }
      &:hover {
        > i {
          transform: rotate(90deg);
        }
      }
    }
    background-color: map-get($theme-colors, navblue) !important;
    .sub-arrow {
        border-radius: $border-radius !important;
        border: none !important;
        transition: transform 0.2s ease;
        margin-right: 0 !important;
        margin-left: 0 !important;
        position: absolute;
        top: 50%;
        right: 0;
        margin: -.7em .5em 0 0;
        border: 1px solid rgba(0,0,0,.1);
        border-radius: .25rem;
        padding: 0;
        width: 2em;
        height: 1.4em;
        font-size: 1.25rem;
        line-height: 1.2em;
        text-align: center;
    
        &:before {
        content: '\f105' !important;
        font-family: 'Font Awesome 5 Pro';
        font-weight: lighter;
        margin-right: 0 !important;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) rotate(90deg);
        body.page-type-10 .wysiwyg & {
            display: none !important;
        }
        }
    
        svg {
        margin-right: 0 !important;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        }
    }

  
    overflow-y: auto;
    position: fixed;
    z-index: 1055;
    top: 0;
    right: auto;
    bottom: 0;
    left: 100%;
    opacity: 0;
    visibility: hidden;
    will-change: transform;
    transition: .3s ease-out;
  
    > * {
      width: 80vw;
      max-width: 400px;
      @include media-breakpoint-down(xs) {
        & {
          max-width: 100% !important;
          width: 100vw !important;
        }
      }
    }
  
    #navbarSupportedContent {
      display: block !important;
      height: auto !important;
    }
  
    ul {
      width: auto !important;
      margin: 0 !important;
      border: none !important;
      background-color: transparent !important;
      ul {
        li {
          a {
            font-size: 16px;
            line-height: 26px;
            font-family: 'Open Sans';
            font-weight: 700;
          }
        }
      }
  
      li {
        a {
          font-size: 20px;
          line-height: 30px;
          position: relative;
          font-family: 'Open Sans';
          font-weight: 700;
          color: rgba(255,255,255,0.75);
          display: block;
          letter-spacing: 0;
          padding-top: 5px !important;
          padding-bottom: 5px !important;
          padding-left: $spacer * 1.5 !important;
          background-color: transparent !important;
  
          &:before {
            content: '';
            display: block;
            position: absolute;
            z-index: -1;
            top: 5%;
            bottom: 5%;
            left: 10%;
            right: 10%;
            transition: 0.3s ease;
            border-radius: $border-radius;
            background-color: rgba(map-get($theme-colors, dark), 0.1) !important;
            opacity: 0;
          }
          body[user-agent*="Mobile"] &:hover:before {
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            opacity: 1;
          }
  
          &:not(.has-submenu) {
            padding-right: $spacer * 1.5 !important;
          }
        }
  
        a.highlighted {
          background-color: transparent !important;
        }
      }
  
      ul {
        margin-left: $spacer !important;
        margin-bottom: 1rem;
      }
    }
  }
  