section.testimonials {
    h2 {
        font-family: quicksand, sans-serif;
        font-size: 26px;
        font-weight: 500 !important;
        color: #282828 !important;
        
        span {
          color: #114B5F !important;
          font-weight: 700 !important;
        }
    }
    .divider-mod {
        border: 2px solid #88D498;
        width: 18%;
        text-align: center;
        margin: 0 auto 175px;
    }
    .wrap {
        background-color: #1A936F;
        overflow: hidden;
        padding: 20px 0;
        min-height: 480px;

        .quotes {
            left: 0;
            font-size: 50px;
            color: #ffffff;
            margin-bottom: 40px;
        }
        blockquote {
            line-height: 45px !important;
            color: #ffffff !important;
        }
        .mod {
            padding: 0 25px 0;
        }
        .owl-carousel .owl-dots {
            left: 7%;
        }
        .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev {
            display: none !important;
        }
        .h6 {
            font-weight: bold;
        }
        a {
            color: #ffffff;

            &:hover {
                color: $secondary;
            }
        }    
        cite {
            font-family: quicksand, sans-serif;
            font-size: 16px;
            font-weight: 400 !important;
            color: #ffffff;
            margin-top: 30px;
            display: block;
    
            :nth-child(2) {
                font-weight: 700;
            }
            :nth-child(3) {
                display: none !important;
            }
        }
        .btn {
            padding: 10px 30px;
            z-index: 1;
        }
    }
    .testIMG {
        background: url('/prositesStyles/StyleLibrary/6518/images/testImg.jpg');
        background-position: 100% 0;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 230px;
    }
}