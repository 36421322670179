.font-size-16px {
font-size:16px !important;
}

@media screen and (min-width: 992px) {.mt-lg-10px {
margin-top:10px !important;
}

}
 .mr-5px {
margin-right:5px !important;
}

.p-0 {
padding:0px !important;
}

.py-5px {
padding-top:5px !important;padding-bottom:5px !important;
}

.h-100 {
height:100% !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.mb-20px {
margin-bottom:20px !important;
}

@media screen and (min-width: 768px) {.mb-md-0 {
margin-bottom:0px !important;
}

}
 .m-0 {
margin:0px !important;
}

.ml-10px {
margin-left:10px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.w-100 {
width:100% !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.p-0 {
padding:0px !important;
}

.m-0 {
margin:0px !important;
}

.p-0 {
padding:0px !important;
}

.h-100 {
height:100% !important;
}

.mt-15px {
margin-top:15px !important;
}

.m-0 {
margin:0px !important;
}

.font-weight-500 {
font-weight:500 !important;
}

.mb-20px {
margin-bottom:20px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mt-20px {
margin-top:20px !important;
}

@media screen and (min-width: 992px) {.pr-lg-30px {
padding-right:30px !important;
}

}
 .mb-25px {
margin-bottom:25px !important;
}

.mt-30px {
margin-top:30px !important;
}

.px-15px {
padding-left:15px !important;padding-right:15px !important;
}

@media screen and (min-width: 768px) {.px-md-0 {
padding-left:0px !important;padding-right:0px !important;
}

}
 .py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

.px-0 {
padding-left:0px !important;padding-right:0px !important;
}

@media screen and (min-width: 992px) {.py-lg-175px {
padding-top:175px !important;padding-bottom:175px !important;
}

}
 .py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

.mb-40px {
margin-bottom:40px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mb-15px {
margin-bottom:15px !important;
}

.bg-200 {
color:200px !important;
}

.my-10px {
margin-top:10px !important;margin-bottom:10px !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.pb-10px {
padding-bottom:10px !important;
}

.mr-10px {
margin-right:10px !important;
}

@media screen and (min-width: 992px) {.py-lg-150px {
padding-top:150px !important;padding-bottom:150px !important;
}

}
 .py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-0 {
padding-top:0px !important;padding-bottom:0px !important;
}

}
 @media screen and (min-width: 992px) {.pr-lg-50px {
padding-right:50px !important;
}

}
 .pb-20px {
padding-bottom:20px !important;
}

.py-25px {
padding-top:25px !important;padding-bottom:25px !important;
}

.mr-10px {
margin-right:10px !important;
}

.mr-10px {
margin-right:10px !important;
}

.m-0 {
margin:0px !important;
}

.pt-25px {
padding-top:25px !important;
}

.pb-5px {
padding-bottom:5px !important;
}

@media screen and (min-width: 992px) {.mt-lg-0 {
margin-top:0px !important;
}

}
 .mt-100px {
margin-top:100px !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.mb-50px {
margin-bottom:50px !important;
}

@media screen and (min-width: 992px) {.pr-lg-50px {
padding-right:50px !important;
}

}
 .line-height-32px {
line-height:32px !important;
}

.mt-20px {
margin-top:20px !important;
}

.mb-50px {
margin-bottom:50px !important;
}

.pb-30px {
padding-bottom:30px !important;
}

@media screen and (min-width: 992px) {.py-lg-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

}
 .py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.mb-15px {
margin-bottom:15px !important;
}

.mb-20px {
margin-bottom:20px !important;
}

.mt-20px {
margin-top:20px !important;
}

.pb-30px {
padding-bottom:30px !important;
}

@media screen and (min-width: 992px) {.py-lg-140px {
padding-top:140px !important;padding-bottom:140px !important;
}

}
 .py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.mb-lg-0 {
margin-bottom:0px !important;
}

}
 .mb-30px {
margin-bottom:30px !important;
}

.py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

.mb-125px {
margin-bottom:125px !important;
}

.ml-0 {
margin-left:0px !important;
}

@media screen and (min-width: 992px) {.ml-lg-15px {
margin-left:15px !important;
}

}
 @media screen and (min-width: 992px) {.mt-lg-0 {
margin-top:0px !important;
}

}
 .mt-20px {
margin-top:20px !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.opacity-25 {
opacity:25 !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.font-size-16px {
font-size:16px !important;
}

.mx-5px {
margin-left:5px !important;margin-right:5px !important;
}

@media screen and (min-width: 768px) {.mr-md-0 {
margin-right:0px !important;
}

}
 @media screen and (min-width: 768px) {.ml-md-10px {
margin-left:10px !important;
}

}
 .font-size-12px {
font-size:12px !important;
}

.p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.h-100 {
height:100% !important;
}

.m-0 {
margin:0px !important;
}

.w-100 {
width:100% !important;
}

.h-100 {
height:100% !important;
}

.p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.px-4 {
padding-left:4px !important;padding-right:4px !important;
}

.pt-4 {
padding-top:4px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-1 {
margin-left:1px !important;margin-right:1px !important;
}

.pt-2 {
padding-top:2px !important;
}

@media screen and (min-width: 768px) {.pt-md-4 {
padding-top:4px !important;
}

}
 .pb-4 {
padding-bottom:4px !important;
}

.px-2 {
padding-left:2px !important;padding-right:2px !important;
}

.w-100 {
width:100% !important;
}

.p-25px {
padding:25px !important;
}

.font-size-16px {
font-size:16px !important;
}

.mx-5px {
margin-left:5px !important;margin-right:5px !important;
}

@media screen and (min-width: 768px) {.mr-md-0 {
margin-right:0px !important;
}

}
 @media screen and (min-width: 768px) {.ml-md-10px {
margin-left:10px !important;
}

}
 .opacity-75 {
opacity:75 !important;
}

