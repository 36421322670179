/*
This is where you write custom SASS
*/

section.hero-section .owl-carousel .owl-item img {
  width: auto;
}
section.blog .wrap-blog2 {
  background-position: 50% 0 !important;
  }
section.blog .company-name {
  position: absolute;
  left: 905px;
  right: 0;
  top: 70px;
}
section.testimonials .divider-mod {
  margin: 0 auto 53px !important;
  }

  input#ctl00_ctl17_TextPhone {
      margin-bottom: 1rem;
  }
@media (max-width: 1199px) {
  .panel-image-overlay{
      opacity: 0.9 !important;
  }
}
@media (max-width: 991.98px) {
  section.blog .company-name {
      top: 400px !important;
  }
}
@media (max-width: 767.98px) {
  section.blog .company-name {
      top: 480px !important;
  }
}
@media (max-width: 1200px) {
  section.hero-section .mod .display-1 {
  font-size: 47px;
  line-height: 62px;
   }
  .tag-wrap {
      margin-top: 25px!important;
  }
}


 #ctl00_ContentPlaceHolder1_OfficeRepeater_ctl00_QuickContact1_CaptchaBaseControl1_btnSend, #ctl00_ContentPlaceHolder1_OfficeRepeater_ctl01_QuickContact1_CaptchaBaseControl1_btnSend {
    background-color: #114B5F;
    border-color: #114B5F;
 }

 #ctl00_ContentPlaceHolder1_OfficeRepeater_ctl00_QuickContact1_OfficeNotesPanel, 
 #ctl00_ContentPlaceHolder1_OfficeRepeater_ctl01_QuickContact1_OfficeNotesPanel {
  color: #fff!important;
 }

/* 9.7. Divider */
.divider, 
.divider-border, 
.divider-small-border, 
.divider-wrap{
  margin-bottom:0
}
.divider{
  height:20px
}
.divider-border{
  margin:35px auto 35px auto;
  background-color:rgba(0,0,0,0.12);
  height:1px
}
.span_12.light .divider-border{
  background-color:rgba(255,255,255,0.3)
}

.divider-small-border{
  margin:25px 0;
  width:20%;
  height:1px;
  background-color:#ddd;
}
.divider-border[data-animate="yes"], 
.divider-small-border[data-animate="yes"]{
  visibility:hidden;
  transform-origin:left;
  -webkit-transform-origin:left
}

.divider-wrap[data-alignment="center"] .divider-small-border {
  transform-origin:center;
  -webkit-transform-origin:center;
  margin: 25px auto;
}
.divider-wrap[data-alignment="right"] {
  text-align: right;
}
.divider-wrap[data-alignment="right"] .divider-small-border {
  transform-origin: right;
  -webkit-transform-origin: right;
  margin-right: 0;
  margin-left: 0;
  display: inline-block;
}
body .wpb_separator, body .vc_text_separator{
  border-color:rgba(0,0,0,0.06)
}
body .vc_text_separator div{
  background-color:#f8f8f8;
  font-family:'Open Sans';
  font-weight:300;
  font-size:22px;
  line-height:32px;
  letter-spacing:0.5px;
  top:-20px
}
.light .divider-small-border{
  background-color:rgba(255,255,255,0.6)
}

section.blog {
  position: relative;
  
  .mod {
    a {
      font-family: quicksand, sans-serif;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      display: inline-block;
      margin-top: 20px;
      letter-spacing: 2px;

      &::after {
        font-family: "Font Awesome 5 Pro";
        content: "\f061";
        display: inline-block;
        position: relative;
        margin-left: 10px;
        transition: .3s ease;
      }

      &:hover {
        &::after {
              margin-left: 15px !important;
  
          }
      }
    }
  }
  .mod2 {
    a {
      font-family: quicksand, sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      letter-spacing: 0px;
    }
    span {
      font-family: quicksand, sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-transform: capitalize;
      letter-spacing: 0px;
    }
  }
  .logo-wrap {
    text-align: center;
    z-index: 1;
    margin: 0 auto;
    left: auto;
    right: 335px;
    top: 155px;

    img {
      z-index: 1;
    }
    p {
      font-family: quicksand, sans-serif;
      font-size: 26px;
      line-height: 30px;
      font-weight: 500;
      z-index: 1;
      margin-top: 30px;

      span {
        color: #1c6c72;
        font-weight: 700;
      }
    }
  }
  .container-fluid {
    position: relative;
    overflow: hidden;
  }
  #ctl00_ctl15_Repeater1_ctl00_articleImg1, #ctl00_ctl15_Repeater1_ctl01_articleImg1,
  #ctl00_ctl15_Repeater1_ctl02_articleImg1 {
    display: none !important;
  }
  .wrap-blog1 {
    background: url('/PrositesStyles/StyleLibrary/6617/images/blog1.jpg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 25px;
  }
  #ctl00_ctl15_Repeater1_ctl00_blogCard {
    background-color: rgba($color: #1A936F, $alpha: 0.9);
    height: 835px;
    padding: 305px 45px 50px;
    width: 450px;
    text-align: center;

    p {
      display: none !important;
    }
    .font-size-11 {
      display: none !important;
    }
    .badge {
      span {
        font-family: muli, sans-serif;
        letter-spacing: 0;
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        text-transform: capitalize;
        color: #282828;
      }
    }
    .h4 {
      letter-spacing: 0;
      font-size: 32px;
      line-height: 40px;
      font-weight: 400;
      color: #282828;

      &:hover {
        color: #88d498;
        transform:scale(1.1) translateY(-10px);
        -webkit-transform:scale(1.1) translateY(-10px);
      }
    }
  }
  .wrap-blog2 {
    background: url('/prositesStyles/StyleLibrary/6617/images/blog2.jpg');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;
  }
  #ctl00_ctl15_Repeater1_ctl01_blogCard {
    height: 835px;
    background-color: rgba($color: #fae1b4, $alpha: 0.9);
    padding: 305px 15px 50px 45px;
    width: 465px;
    display: block;
    float: left;

    .badge {
      span {
        font-family: muli, sans-serif;
        letter-spacing: 0;
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        text-transform: capitalize;
        color: #282828;
      }
    }
    .h4 {
      font-family: quicksand, sans-serif;
      font-size: 26px;
      line-height: 30px;
      font-weight: 500;
      text-transform: capitalize;
      color: #282828;
    }
  } 
  #ctl00_ctl15_Repeater1_ctl02_blogCard {
    height: 835px;
    background-color: rgba($color: #fae1b4, $alpha: 0.9);
    padding: 305px 45px 50px 15px;
    width: 465px;
    display: block;
    float: left;

    .badge {
      span {
        font-family: muli, sans-serif;
        letter-spacing: 0;
        font-size: 16px;
        line-height: 25px;
        font-weight: 400;
        text-transform: capitalize;
        color: #282828;
      }
    }
    .h4 {
      font-family: quicksand, sans-serif;
      font-size: 26px;
      line-height: 30px;
      font-weight: 500;
      text-transform: capitalize;
      color: #282828;
    }
  }
}
.row-bg-wrap {
  margin-left: -50vw;
  left: 50%;
  width: 100vw;
}
/* Shape divider layer */
.nectar-shape-divider-wrap {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 150px;
  z-index: 3;
  transform: translateZ(0);
}
.post-area.span_9 .nectar-shape-divider-wrap {
  overflow: hidden;
}
.nectar-shape-divider-wrap[data-position="top"] {
  top: -1px;
  bottom: auto;
}
.nectar-shape-divider-wrap[data-position="top"] {
  transform: rotate(180deg) 
}
.nectar-shape-divider-wrap[data-front="true"] {
  z-index: 50;
}

@media only screen and (min-width: 1000px) {
	.nectar-shape-divider-wrap[data-style="curve"] .nectar-shape-divider {
	  filter: blur(0.5px);
	  transform: scale(1.03);
	}
}

.nectar-shape-divider-wrap[data-style="waves_opacity"] svg path:first-child {
  opacity: 0.6;
}
@media only screen and (min-width: 1000px) {
  .nectar-shape-divider-wrap[data-style="clouds"] .nectar-shape-divider {
    min-width: 1700px;
  }
}
@media only screen and (max-width: 999px) {
  .nectar-shape-divider-wrap[data-style="clouds"] .nectar-shape-divider {
    min-width: 800px;
  }
}
@media only screen and (max-width: 690px) {
  .nectar-shape-divider-wrap[data-style="clouds"] .nectar-shape-divider {
    min-width: 690px;
  }
}
.nectar-shape-divider-wrap[data-style="fan"] svg {
  width: 102%;
  left: -1%;
}
.nectar-shape-divider-wrap[data-style="fan"] svg polygon:nth-child(2) {
  opacity: 0.15;
}
.nectar-shape-divider-wrap[data-style="fan"] svg rect {
  opacity: 0.3;
}
.nectar-shape-divider-wrap[data-style="mountains"] svg path:first-child {
  opacity: 0.1;
}
.nectar-shape-divider-wrap[data-style="mountains"] svg path:nth-child(2) {
  opacity: 0.12;
}
.nectar-shape-divider-wrap[data-style="mountains"] svg path:nth-child(3) {
  opacity: 0.18;
}
.nectar-shape-divider-wrap[data-style="mountains"] svg path:nth-child(4) {
  opacity: 0.33;
}
.nectar-shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(1), .nectar-shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(1) {
  opacity: 0.15;
}
.nectar-shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(2), .nectar-shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(2) {
  opacity: 0.3;
}
.nectar-shape-divider {
  width: 100%;
  left: 0;
  bottom: -1px;
  height: 100%;
  position: absolute;
}
.nectar-shape-divider-wrap.no-color .nectar-shape-divider {
  fill: #fff;
}
@media only screen and (max-width: 999px) {
  .nectar-shape-divider-wrap:not([data-using-percent-val="true"]) .nectar-shape-divider {
    height: 75%;
  }
  .nectar-shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"]) .nectar-shape-divider {
    height: 55%;
  }
}
@media only screen and (max-width: 690px) {
  .nectar-shape-divider-wrap:not([data-using-percent-val="true"]) .nectar-shape-divider {
    height: 33%;
  }
  .nectar-shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"]) .nectar-shape-divider {
    height: 33%;
  }
}

.media {
  width: 300px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.18);
  z-index: 9999;
  display: block;
  position: relative;

  p {
    margin: 10px 0 0;
    line-height: 23px;
    text-align: left;
    padding: 0 15px 10px;
  }
}

section.content-section {
  min-height: 1040px;
  color: #999;

  h1 {
    font-size: 50px;
    color: $primary;
    text-align: center;
    line-height: 55px;
    font-weight: 400;
  }
  .h4 {
    font-family: quicksand, sans-serif;
    color: $secondary;
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    margin: 10px 0 30px 0;
    cursor: pointer;

    &:hover {
      color: $primary;
    }

    i {
      font-size: 11px;
      position: relative;
      top: -2px;
    }
  }
  .btm-wrap {
    background-color: #edfcfd;
    padding: 40px;

    span {
      display: block;
      position: relative;
      top: 15px;
    }
  }
}

#ajax-loading-screen, #ajax-loading-screen[data-effect="center_mask_reveal"] span {
  background-color: $primary;
}
.default-loading-icon {
  border-color: rgba(255,255,255,0.2);
}
#ajax-loading-screen .reveal-1 {
  background-color: $primary;
}
#ajax-loading-screen .reveal-2 {
  background-color: #88D498;
}
.portfolio-loading, #ajax-loading-screen .loading-icon, .loading-icon, .pp_loaderIcon {
  background-image: url("");
}

#ajax-loading-screen{
  background-color:#fff;
  width:100%;
  height:100%;
  position:fixed;
  top:0;
  left:0;
  display:none;
  z-index:1000000000
}
#ajax-loading-screen .reveal-1, 
#ajax-loading-screen .reveal-2{
  position:absolute;
  left:100%;
  top:0;
  width:100%;
  height:100%
}
#ajax-loading-screen[data-effect*="horizontal_swipe"]{
  background-color:transparent!important;
  left:-100%
}
body[data-ajax-transitions="true"] #ajax-loading-screen[data-method="standard"][data-effect*="horizontal_swipe"]{
  display:block
}
body[data-ajax-transitions="true"][data-apte="horizontal_swipe_basic"] #ajax-loading-screen .reveal-2 {
  display: none;
}
#ajax-loading-screen.in-from-right{
  left:0;
}
.no-cssanimations #ajax-loading-screen.loaded .reveal-1, 
.no-cssanimations #ajax-loading-screen.loaded .reveal-2{
  display:none
}
#ajax-loading-screen.loaded .reveal-1{
  backface-visibility: hidden;
  -webkit-animation:nectar-anim-effect-2-2 1.85s cubic-bezier(0.67,0,0.3,1) forwards;
  animation:nectar-anim-effect-2-2 1.85s cubic-bezier(0.67,0,0.3,1) forwards
}
#ajax-loading-screen.loaded .reveal-2{
  backface-visibility: hidden;
  -webkit-animation:nectar-anim-effect-2-1 1.85s cubic-bezier(0.67,0,0.3,1) forwards;
  animation:nectar-anim-effect-2-1 1.85s cubic-bezier(0.67,0,0.3,1) forwards
}
#ajax-loading-screen.loaded.in-from-right .reveal-1{
  -webkit-animation:nectar-anim-effect-2-1 1.85s cubic-bezier(0.67,0,0.3,1) forwards;
  animation:nectar-anim-effect-2-1 1.85s cubic-bezier(0.67,0,0.3,1) forwards
}
body[data-apte="horizontal_swipe_basic"] #ajax-loading-screen.loaded.in-from-right .reveal-1{
  -webkit-animation:nectar-anim-effect-2-1 1.1s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
  animation:nectar-anim-effect-2-1 1.1s cubic-bezier(0.215, 0.61, 0.355, 1) forwards 
}
#ajax-loading-screen.loaded.in-from-right .reveal-2{
  -webkit-animation:nectar-anim-effect-2-2 1.85s cubic-bezier(0.67,0,0.3,1) forwards;
  animation:nectar-anim-effect-2-2 1.85s cubic-bezier(0.67,0,0.3,1) forwards
}
body[data-ajax-transitions="true"] #ajax-loading-screen[data-effect*="horizontal_swipe"].hidden{
  display:none
}
body[data-ajax-transitions="true"] #ajax-loading-screen[data-effect*="horizontal_swipe"].hidden.loaded.in-from-right {
  display: block;
}
@-webkit-keyframes nectar-anim-effect-2-1{
  0%{
    -ms-transform:translateX(0);
    -webkit-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0)
  }
  30%, 100%{
    -ms-transform:translateX(-100%);
    -webkit-transform:translate3d(-100%,0,0);
    transform:translate3d(-100%,0,0);
    -webkit-animation-timing-function:cubic-bezier(0.67,0,0.3,1);
    animation-timing-function:cubic-bezier(0.67,0,0.3,1)
  }
}
@keyframes nectar-anim-effect-2-1{
  0%{
    -ms-transform:translateX(0);
    -webkit-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0)
  }
  30%, 100%{
    -ms-transform:translateX(-100%);
    -webkit-transform:translate3d(-100%,0,0);
    transform:translate3d(-100%,0,0);
    -webkit-animation-timing-function:cubic-bezier(0.67,0,0.3,1);
    animation-timing-function:cubic-bezier(0.67,0,0.3,1)
  }
}
@-webkit-keyframes nectar-anim-effect-2-2{
  0%,14.5%{
    -ms-transform:translateX(0);
    -webkit-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0)
  }
  34.5%, 100%{
    -ms-transform:translateX(-100%);
    -webkit-transform:translate3d(-100%,0,0);
    transform:translate3d(-100%,0,0);
    -webkit-animation-timing-function:cubic-bezier(0.67,0,0.3,1);
    animation-timing-function:cubic-bezier(0.67,0,0.3,1)
  }
}
@keyframes nectar-anim-effect-2-2{
  0%,14.5%{
    -ms-transform:translate3d(0,0,0);
    -webkit-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0)
  }
  34.5%, 100%{
    -ms-transform:translate3d(-100%,0,0);
    -webkit-transform:translate3d(-100%,0,0);
    transform:translate3d(-100%,0,0);
    -webkit-animation-timing-function:cubic-bezier(0.67,0,0.3,1);
    animation-timing-function:cubic-bezier(0.67,0,0.3,1)
  }
}
body[data-ajax-transitions="true"] #ajax-loading-screen[data-method="standard"], 
body[data-ajax-transitions="true"] #ajax-loading-screen[data-effect*="horizontal_swipe"][data-method="ajax"], 
body[data-ajax-transitions="true"] #ajax-loading-screen[data-method="standard"] .loading-icon{
  display:block;
  opacity:1
}
#ajax-loading-screen .loading-icon{
  background-repeat:no-repeat;
  background-position:center center;
  display:block;
  position:absolute;
  opacity:0;
  top:0;
  left:0;
  width:100%;
  height:100%
}
.default-loading-icon{
  top:50%;
  border-radius:100%;
  height:40px;
  width:40px;
  margin:0 auto;
  display:block;
  position:relative;
  border:3px solid #e5e5e5;
  margin-top:-20px
}
.loading-icon .material-icon .spinner{
  width:60px;
  height:60px;
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  margin:auto
}
.loading-icon .material-icon .spinner{
  -webkit-animation:material-rotate-all 1s linear infinite;
  animation:material-rotate-all 1s linear infinite
}
.loading-icon .material-icon .spinner .right-side, 
.loading-icon .material-icon .spinner .left-side{
  -webkit-animation:material-fade-in-first 1.2s linear infinite alternate;
  animation:material-fade-in-first 1.2s linear infinite alternate
}
.loading-icon .material-icon .spinner.color-2 .right-side, 
.loading-icon .material-icon .spinner.color-2 .left-side{
  -webkit-animation:material-fade-in-second 1.2s linear infinite alternate;
  animation:material-fade-in-second 1.2s linear infinite alternate
}
.loading-icon .material-icon .right-side, 
.loading-icon .material-icon .left-side{
  width:50%;
  height:100%;
  position:absolute;
  top:0;
  overflow:hidden
}
.loading-icon .material-icon .left-side{
  left:0
}
.loading-icon .material-icon .right-side{
  right:0
}
.loading-icon .material-icon .bar{
  width:100%;
  height:100%;
  -webkit-border-radius:200px;
  border-radius:200px 0 0 200px;
  border:6px solid #666;
  position:relative
}
.loading-icon .material-icon .bar:after{
  content:"";
  width:6px;
  height:6px;
  display:block;
  background:#666;
  position:absolute;
  -webkit-border-radius:6px;
  border-radius:6px
}
.loading-icon .material-icon .right-side .bar{
  -webkit-border-radius:0;
  border-radius:0 200px 200px 0;
  border-left:none;
  -webkit-transform:rotate(-10deg);
  -webkit-transform-origin:left center;
  transform:rotate(-10deg);
  transform-origin:left center;
  -webkit-animation:material-rotate-right 0.75s linear infinite alternate;
  animation:material-rotate-right 0.75s linear infinite alternate
}
.loading-icon .material-icon .right-side .bar:after{
  bottom:-6px;
  left:-3px
}
.loading-icon .material-icon .left-side .bar{
  border-right:none;
  -webkit-transform:rotate(10deg);
  transform:rotate(10deg);
  -webkit-transform-origin:right center;
  transform-origin:right center;
  -webkit-animation:material-rotate-left 0.75s linear infinite alternate;
  animation:material-rotate-left 0.75s linear infinite alternate
}
.loading-icon .material-icon .left-side .bar:after{
  bottom:-6px;
  right:-3px
}
@keyframes material-rotate-left{
  to{
    transform:rotate(30deg)
  }
  from{
    transform:rotate(175deg)
  }
}
@keyframes material-rotate-right{
  from{
    transform:rotate(-175deg)
  }
  to{
    transform:rotate(-30deg)
  }
}
@keyframes material-rotate-all{
  from{
    transform:rotate(0deg)
  }
  to{
    transform:rotate(-360deg)
  }
}
@keyframes material-fade-in-first{
  from{
    opacity:1
  }
  to{
    opacity:0
  }
}
@keyframes material-fade-in-second{
  from{
    opacity:0
  }
  to{
    opacity:1
  }
}
.loading-icon .material-icon{
  top:50%;
  height:65px;
  width:65px;
  position:relative;
  margin:-32px auto 0 auto;
  display:block
}
.default-loading-icon:before{
  position:absolute;
  content:"";
  border-color:rgba(0,0,0,1) transparent transparent;
  border-radius:100%;
  border-style:solid;
  border-width:3px;
  display:block;
  height:100%;
  width:100%;
  top:-3px;
  left:-3px
}
.loading-icon.spin, 
.portfolio-loading.spin, 
.pp_loaderIcon.spin, 
.default-loading-icon.spin{
  animation:2s cubic-bezier(0.63,0.045,0.34,1) 0s normal none infinite spinning_animation;
  -webkit-animation:2s cubic-bezier(0.63,0.045,0.34,1) 0s normal none infinite spinning_animation
}
@keyframes spinning_animation{
  0%{
    transform:scale(1) rotate(0deg);
  }
  50%{
    transform:scale(0.8) rotate(360deg);
  }
  100%{
    transform:scale(1) rotate(720deg);
  }
}
@-webkit-keyframes spinning_animation{
  0%{
    -webkit-transform:scale(1) rotate(0deg)
  }
  50%{
    -webkit-transform:scale(0.8) rotate(360deg)
  }
  100%{
    -webkit-transform:scale(1) rotate(720deg)
  }
}
@keyframes bounce_in_animation{
  0%{
    transform:scale(0,0);
  }
  20%{
    transform:scale(1.4,1.4);
  }
  50%{
    transform:scale(0.8,0.8);
  }
  85%{
    transform:scale(1.1,1.1);
  }
  100%{
    transform:scale(1,1);
  }
}
@-webkit-keyframes bounce_in_animation{
  0%{
    -webkit-transform:scale(0,0)
  }
  20%{
    -webkit-transform:scale(1.4,1.4)
  }
  50%{
    -webkit-transform:scale(0.8,0.8)
  }
  85%{
    -webkit-transform:scale(1.1,1.1)
  }
  100%{
    -webkit-transform:scale(1,1)
  }
}
@keyframes grow_in_animation{
  0%{
    transform:scale(0,0);
  }
  100%{
    transform:scale(1,1);
  }
}
@-webkit-keyframes grow_in_animation{
  0%{
    -webkit-transform:scale(0,0)
  }
  100%{
    -webkit-transform:scale(1,1)
  }
}
body #ajax-loading-screen[data-effect="center_mask_reveal"]{
  background-color:transparent
}
body[data-ajax-transitions="true"] #ajax-loading-screen[data-effect="center_mask_reveal"].hidden{
  display:none
}
#ajax-loading-screen[data-effect="center_mask_reveal"] span{
  position:absolute;
  background:#fff;
  z-index:100;
  -webkit-transition:0.8s cubic-bezier(0.12,0.75,0.4,1);
  transition:0.8s cubic-bezier(0.12,0.75,0.4,1) 
}
#ajax-loading-screen .mask-top{
  top:0;
  left:0;
  height:50%;
  width:100%
}
#ajax-loading-screen .mask-right{
  top:0;
  right:0;
  height:100%;
  width:50%
}
#ajax-loading-screen .mask-bottom{
  bottom:0;
  right:0;
  height:50%;
  width:100%
}
#ajax-loading-screen .mask-left{
  top:0;
  left:0;
  height:100%;
  width:50%
}
#ajax-loading-screen.loaded .mask-top{
  -webkit-transform:translateY(-100%) translateZ(0);
  -ms-transform:translateY(-100%) translateZ(0);
  transform:translateY(-100%) translateZ(0)
}
#ajax-loading-screen.loaded .mask-right{
  -webkit-transform:translateX(100%) translateZ(0);
  -ms-transform:translateX(100%) translateZ(0);
  transform:translateX(100%) translateZ(0)
}
#ajax-loading-screen.loaded .mask-bottom{
  -webkit-transform:translateY(100%) translateZ(0);
  -ms-transform:translateY(100%) translateZ(0);
  transform:translateY(100%) translateZ(0)
}
#ajax-loading-screen.loaded .mask-left{
  -webkit-transform:translateX(-100%) translateZ(0);
  -ms-transform:translateX(-100%) translateZ(0);
  transform:translateX(-100%) translateZ(0)
}
#ajax-loading-screen[data-effect="center_mask_reveal"].set-to-fade span, 
#ajax-loading-screen[data-effect="center_mask_reveal"].set-to-fade.loaded span {
  width:100%;
  height:100%;
  top:0;
  left:0;
  -webkit-transform:none;
	transform:none; 
}

/* Phone and Tablet */
@media only screen and (min-width : 1px) and (max-width : 999px) {
  body[data-ajax-transitions="true"] #ajax-loading-screen[data-method="standard"][data-disable-mobile="1"] {
    display: none;
  }
}
  
/* Higher Res Tablets */
@media only screen and (max-device-width: 2600px) {
  body[data-ajax-transitions="true"].using-mobile-browser #ajax-loading-screen[data-method="standard"][data-disable-mobile="1"] {
    display: none;
  }
}

section.content-section {
  .divider-mod {
    border: 2px solid #88D498;
    width: 120px;
    text-align: center;
    margin: 0 auto 30px;
  }
}

section.address {
    // position: fixed;
    // top: 0;
    // width: 100%;
    height: 60px;
    // box-shadow: 0 4px 8px 0 rgba($color: #000000, $alpha: 0.18);
    z-index: 5;
    // transition: all .5s;
    text-align: center;

    .ph {
      padding: 20px 0;
      background-color: #fff;
      color: #1A936F;
    }
    .brdr {
      color: rgba(35, 137, 144, 0.5);
      position: relative;
      font-size: 28px;
      font-weight: 200;
      top: 2px;
    }
    a {
      color: #114B5F;

      &:hover {
        color: #1A936F;
      }
    }
  .phone-col {
      // svg:not([class*="angle-down"]),
      // i:not([class*="angle-down"]) {
      //     display: none !important;
      // }
      ul[id*="rblPhones"] a{
          font-size: 12px !important;
          text-transform: uppercase;
          color: $primary !important;
          letter-spacing: 0.15em;
      }
      .phone-dropdown-menu ul, .address-dropdown-menu ul {
          padding: 0;
          margin: 0;
      }
      .phone-dropdown-menu ul a .row, 
      .address-dropdown-menu ul a .row {
          padding: .5rem .25rem .5rem .5rem;
          position: relative;
      }
      .phone-dropdown-menu ul a, 
      .address-dropdown-menu ul a {
          line-height: 1.4em;
          transition: 0.3s ease;
          opacity: 0;
          visibility: hidden;
          transform: translate3d(0, 1rem, 0);
          display: block;
      }
  }

  .phone-dropdown-menu ul a, 
  .address-dropdown-menu ul a {
      .row {
          &:before {
              content: '';
              display: block;
              position: absolute;
              z-index: -1;
              top: 5%;
              bottom: 5%;
              left: 5%;
              right: 5%;
              transition: 0.3s ease;
              background-color: rgba(15,20,26,0.1) !important;
              opacity: 0;
          }
      }
      &:hover {
          .row {
              &:before {
                  right: 0;
                  left: 0;
                  top: 0;
                  bottom: 0;
                  opacity: 1;
              }
          }
      }
  }
  .phone-dropdown-menu,
  .address-dropdown-menu {
      transition: opacity 0.2s ease, visibility 0.2s ease, margin-top 0.2s ease;
      display: block;
      opacity: 0;
      visibility: hidden;
      padding: 1rem * 0.5;
      border: none;
      background: transparent;
      margin-top: -1.5rem !important;
      max-width: 100% !important;
      min-width: 16rem !important;
      margin-right: -5px;
      box-shadow: none !important;

      span[class*="col"] {
        color: #282828 !important;
      }
      .address-suite {
        color: $primary !important;
      }
      .phone-number {
          color: $primary !important;
      }

  &:before {
      content: '';
      display: block;
      position: absolute;
      z-index: -1;
      top: 5%;
      bottom: 25%;
      left: 25%;
      right: 25%;
      transition: 0.3s ease;
      border-radius: $border-radius;
      background-color: #f9f9f9!important;
      box-shadow: $box-shadow;
      opacity: 0;
  }

  &.show {
      opacity: 1;
      visibility: visible;

      &:before {
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      opacity: 1;
      }

      ul {
      @for $i from 1 to 10 {
          a:nth-child(#{$i}) {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
          transition-delay: 0.2 + ($i * 0.025s);
          }
      }
      }
  }
  }
}

.sticky {
  position: fixed !important;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  border-top: 0;
}

.sticky + .hero-section {
  margin-top: 60px;
}

.hide {
  opacity:0;
 
}
.show {
  opacity:1;

}

section.services-section {
	padding-top: calc(100vw * 0.12);
	padding-bottom: calc(100vw * 0.12);

  .h1 {
    font-family: quicksand, sans-serif;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
  }
  h5 {
    font-family: quicksand, sans-serif;
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
  }
  .btn {
    background-color: #88D498 !important;
    padding: 13px 25px;
    font-size: 14px;
    width: 175px;
    color: $white;
    
    i {
        transition: .3s ease;
        opacity: 0;
        transform: translateX(40px) rotate(0deg);
    }
    span {
        transition: .3s ease;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        width: 55%;
    }
    &:hover {
      background-color: #C6DABF !important;

      i {
          opacity: 1;
          transform: translateX(54px) rotate(0);
      }
      span {
          transform: translate3d(-65%,-50%,0);
      }
    }
  }
  .mod {
    height: 475px !important;
    cursor: pointer;

    .icon-wrap {
      position: relative;
      z-index: 999;
    }
  }

  .mod:nth-child(1) {
    padding-bottom: 30px;

    .service {
      background-color: rgba($color: #333333, $alpha: 0.5);
      padding: 15%;
      transition: all 0.5s ease;
      
      &:hover {
        background-color: rgba($color: #333333, $alpha: 0.2);
      }
    }

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 5px;
      background: #88D498;
      transition: width .3s;
    }

    &:hover {
      &::after { 
        width: 100%;
        transition: width .3s;
      }
    }
  }
  .mod:nth-child(2) {
    position: relative;
    top: -30px;
    padding-bottom: 30px;

    .service {
      background-color: rgba($color: #88D498, $alpha: 1);
      padding: 15%;
      transition: all 0.5s ease;
      
      &:hover {
        background-color: rgba($color: #C6DABF, $alpha: 1); 
      }
    }
  }
  .mod:nth-child(3) {
    padding-bottom: 30px;

    .service {
      background-color: rgba($color: #1A936F, $alpha: 1);
      padding: 15%;
      transition: all 0.5s ease;
      
      &:hover {
        background-color: rgba($color: #88D498, $alpha: 1);
      }
    }
  }
  .mod:nth-child(4) {
    padding-bottom: 30px;

    .service {
      background-color: rgba($color: #1A936F, $alpha: 1);
      padding: 15%;
      transition: all 0.5s ease;
      
      &:hover {
        background-color: rgba($color: #88D498, $alpha: 1);
      }
    }
  }
  .mod:nth-child(5) {
    transition: all 0.5s ease;
    position: relative;
    top: -30px;
    padding-bottom: 30px;

    .service {
      background-color: rgba($color: #114B5F, $alpha: 1);
      padding: 15%;
      transition: all 0.5s ease;
      
      &:hover {
        background-color: rgba($color: #114B5F, $alpha: 1);
      }
    }
  }
  .mod:nth-child(6) {
    padding-bottom: 30px;

    .service {
      background-color: rgba($color: #333333, $alpha: 0.5);
      padding: 15%;
      transition: all 0.5s ease;
      
      &:hover {
        background-color: rgba($color: #333333, $alpha: 0.2);
      }
    }

    &::after {
      content: '';
      display: block;
      width: 0;
      height: 5px;
      background: #88D498;
      transition: width .3s;
    }

    &:hover {
      &::after { 
        width: 100%;
        transition: width .3s;
      }
    }
  }

  .box-link {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: block;
    border: 1px solid transparent;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    border-radius: 0;
  }

  /* Shape divider layer */
.nectar-shape-divider-wrap {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 150px;
  z-index: 3;
  transform: translateZ(0);
}
.post-area.span_9 .nectar-shape-divider-wrap {
  overflow: hidden;
}
.nectar-shape-divider-wrap[data-position="top"] {
  top: -1px;
  bottom: auto;
}
.nectar-shape-divider-wrap[data-position="top"] {
  transform: rotate(180deg) 
}
.nectar-shape-divider-wrap[data-front="true"] {
  z-index: 50;
}

@media only screen and (min-width: 1000px) {
	.nectar-shape-divider-wrap[data-style="curve"] .nectar-shape-divider {
	  filter: blur(0.5px);
	  transform: scale(1.03);
	}
}

.nectar-shape-divider-wrap[data-style="waves_opacity"] svg path:first-child {
  opacity: 0.6;
}
@media only screen and (min-width: 1000px) {
  .nectar-shape-divider-wrap[data-style="clouds"] .nectar-shape-divider {
    min-width: 1700px;
  }
}
@media only screen and (max-width: 999px) {
  .nectar-shape-divider-wrap[data-style="clouds"] .nectar-shape-divider {
    min-width: 800px;
  }
}
@media only screen and (max-width: 690px) {
  .nectar-shape-divider-wrap[data-style="clouds"] .nectar-shape-divider {
    min-width: 690px;
  }
}
.nectar-shape-divider-wrap[data-style="fan"] svg {
  width: 102%;
  left: -1%;
}
.nectar-shape-divider-wrap[data-style="fan"] svg polygon:nth-child(2) {
  opacity: 0.15;
}
.nectar-shape-divider-wrap[data-style="fan"] svg rect {
  opacity: 0.3;
}
.nectar-shape-divider-wrap[data-style="mountains"] svg path:first-child {
  opacity: 0.1;
}
.nectar-shape-divider-wrap[data-style="mountains"] svg path:nth-child(2) {
  opacity: 0.12;
}
.nectar-shape-divider-wrap[data-style="mountains"] svg path:nth-child(3) {
  opacity: 0.18;
}
.nectar-shape-divider-wrap[data-style="mountains"] svg path:nth-child(4) {
  opacity: 0.33;
}
.nectar-shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(1), .nectar-shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(1) {
  opacity: 0.15;
}
.nectar-shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(2), .nectar-shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(2) {
  opacity: 0.3;
}
.nectar-shape-divider {
  width: 100%;
  left: 0;
  bottom: -1px;
  height: 100%;
  position: absolute;
}
.nectar-shape-divider-wrap.no-color .nectar-shape-divider {
  fill: #fff;
}
@media only screen and (max-width: 999px) {
  .nectar-shape-divider-wrap:not([data-using-percent-val="true"]) .nectar-shape-divider {
    height: 75%;
  }
  .nectar-shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"]) .nectar-shape-divider {
    height: 55%;
  }
}
@media only screen and (max-width: 690px) {
  .nectar-shape-divider-wrap:not([data-using-percent-val="true"]) .nectar-shape-divider {
    height: 33%;
  }
  .nectar-shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"]) .nectar-shape-divider {
    height: 33%;
  }
}

	@include media-breakpoint-down(md) {
		[class*="col"]:nth-of-type(3n + 1) div.d-flex {
			align-items: flex-end !important;
		}

		[class*="col"]:nth-of-type(3n + 3) div.d-flex {
			align-items: flex-start !important;
		}
	}
	@include media-breakpoint-down(sm) {
		[class*="col"]:nth-of-type(2n + 1) div.d-flex {
			align-items: flex-end !important;
		}

		[class*="col"]:nth-of-type(2n + 2) div.d-flex {
			align-items: flex-start !important;
		}
		span {
			font-size: 14px !important;
		}
		.d-flex.align-items-center.justify-content-center.flex-column
			> div.text-center {
			width: 190px !important;
		}
	}
	@include media-breakpoint-down(xs) {
		[class*="col"]:nth-of-type(2n + 1) div.d-flex {
			align-items: flex-end !important;
		}

		[class*="col"]:nth-of-type(2n + 2) div.d-flex {
			align-items: flex-start !important;
		}
		span {
			font-size: 14px !important;
		}

		.d-flex.align-items-center.justify-content-center.flex-column
			> div.text-center {
			width: 150px !important;
		}
	}
	a.rounded-circle {
		width: 110px;
		height: 110px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 2px solid $primary;
		background: $white;
		&:hover {
			background: $primary;
			i {
				color: $white !important;
			}
		}
	}
}

section.faqs {
  .divider-mod {
    border: 2px solid #88D498;
    width: 19%;
    text-align: left;
    margin: 0 0 40px 0;
  }
  .border-bottom {
    border-bottom: 2px solid #00000014 !important;
  }
  .h2 {
    font-family: quicksand, sans-serif;
    color: $primary;
    font-weight: 500;
  }
  .h2.collapsed {
    color: $secondary;

    &:hover {
      color: $primary;
    }
  }
  [data-toggle="collapse"] {
    .if-open {
        display: inline-block;
        float: right;
        font-size: 30px;
        padding: 0 0 0 25px;
    }
    .if-closed {
        display: none;
    }
    &.collapsed {
        .if-open {
            display: none;
        }
        .if-closed {
            display: inline-block;
            float: right;
            font-size: 30px;
            padding: 0 0 0 25px;
        }
    }
  }
  .title {
    font-family: quicksand, sans-serif;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;

    span {
      font-family: muli, sans-serif;
      color: #114B5F;
      font-weight: 700;
    }
  }
  h5 {
    font-family: quicksand, sans-serif;
    font-size: 20px;
    color: #1A936F;
    font-weight: 500;
  }
  .btn {
    background-color: #88D498 !important;
    padding: 13px 25px;
    font-size: 14px;
    width: 215px;
    color: $white;
    
    i {
        transition: .3s ease;
        opacity: 0;
        transform: translateX(40px) rotate(0deg);
    }
    span {
        transition: .3s ease;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        width: 59%;
    }
    &:hover {
      background-color: #1A936F !important;

      i {
          opacity: 1;
          transform: translateX(72px) rotate(0);
      }
      span {
          transform: translate3d(-65%,-50%,0);
      }
    }
  }
}

section.contact {
  padding: 150px 0;

  .h2 {
    font-family: quicksand, sans-serif;
    font-weight: 500;
  }
  .btn {
    min-width: 175px;
  }
  .form-control {
    border: none;
  }
  .nectar-shape-divider-wrap {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 150px;
    z-index: 3;
    transform: translateZ(0);
  }
  .post-area.span_9 .nectar-shape-divider-wrap {
    overflow: hidden;
  }
  .nectar-shape-divider-wrap[data-position="top"] {
    top: -1px;
    bottom: auto;
  }
  .nectar-shape-divider-wrap[data-position="top"] {
    transform: rotate(180deg) 
  }
  .nectar-shape-divider-wrap[data-front="true"] {
    z-index: 50;
  }
  
  @media only screen and (min-width: 1000px) {
    .nectar-shape-divider-wrap[data-style="curve"] .nectar-shape-divider {
      filter: blur(0.5px);
      transform: scale(1.03);
    }
  }
  
  .nectar-shape-divider-wrap[data-style="waves_opacity"] svg path:first-child {
    opacity: 0.6;
  }
  @media only screen and (min-width: 1000px) {
    .nectar-shape-divider-wrap[data-style="clouds"] .nectar-shape-divider {
      min-width: 1700px;
    }
  }
  @media only screen and (max-width: 999px) {
    .nectar-shape-divider-wrap[data-style="clouds"] .nectar-shape-divider {
      min-width: 800px;
    }
  }
  @media only screen and (max-width: 690px) {
    .nectar-shape-divider-wrap[data-style="clouds"] .nectar-shape-divider {
      min-width: 690px;
    }
  }
  .nectar-shape-divider-wrap[data-style="fan"] svg {
    width: 102%;
    left: -1%;
  }
  .nectar-shape-divider-wrap[data-style="fan"] svg polygon:nth-child(2) {
    opacity: 0.15;
  }
  .nectar-shape-divider-wrap[data-style="fan"] svg rect {
    opacity: 0.3;
  }
  .nectar-shape-divider-wrap[data-style="mountains"] svg path:first-child {
    opacity: 0.1;
  }
  .nectar-shape-divider-wrap[data-style="mountains"] svg path:nth-child(2) {
    opacity: 0.12;
  }
  .nectar-shape-divider-wrap[data-style="mountains"] svg path:nth-child(3) {
    opacity: 0.18;
  }
  .nectar-shape-divider-wrap[data-style="mountains"] svg path:nth-child(4) {
    opacity: 0.33;
  }
  .nectar-shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(1), .nectar-shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(1) {
    opacity: 0.15;
  }
  .nectar-shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(2), .nectar-shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(2) {
    opacity: 0.3;
  }
  .nectar-shape-divider {
    width: 100%;
    left: 0;
    bottom: -1px;
    height: 100%;
    position: absolute;
  }
  .nectar-shape-divider-wrap.no-color .nectar-shape-divider {
    fill: #fff;
  }
  @media only screen and (max-width: 999px) {
    .nectar-shape-divider-wrap:not([data-using-percent-val="true"]) .nectar-shape-divider {
      height: 75%;
    }
    .nectar-shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"]) .nectar-shape-divider {
      height: 55%;
    }
  }
  @media only screen and (max-width: 690px) {
    .nectar-shape-divider-wrap:not([data-using-percent-val="true"]) .nectar-shape-divider {
      height: 33%;
    }
    .nectar-shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"]) .nectar-shape-divider {
      height: 33%;
    }
  }
}

section.news {
  .btn {
    border: 4px solid transparent;
    padding: 20px 40px;
  }
  .btn-outline-white {
      border-color: #fff;
      font-size: 18px;
      font-weight: 600;
  }
  .wrap {
    overflow: hidden;
    position: relative;
    padding: 125px 0;
    border-radius: 5px;
  }
}

footer.foot {
  .bt {
    color: $primary;
    font-size: 14px;
    font-weight: 700;
  }
  h2 {
    color: #394953;
    font-size: 32px;
    line-height: 40px;
    font-weight: 400;
  }
  .btm {
    [id*="_container"] {
      display: inline-block;
      margin: 25px 20px 30px 20px !important;
    }

    .social {
      a {
        color: #394953 !important;
        border-radius: 50%;
        border: 2px solid rgba($color: #394953, $alpha: 0.2);
        height: 46px;
        width: 46px;
  
        &:hover {
          color: #394953 !important;
          border: 2px solid #394953;
        }
      }
    }
  }
}

body.mobile-nav-show #fullPageWrapper {
  transform: translateX(-400px);
}

#fullPageWrapper {
  transition: .3s ease-out;
  // transform: translateX(0);
}

::placeholder {
}

.form-control {
}

.text-initial {
    text-transform: initial;
}

.backdrop-blur {
  background-color: rgba(233, 233, 233, 0.9);
}

@supports ((-webkit-backdrop-filter: blur(50px)) or (backdrop-filter: blur(50px))) {
  .backdrop-blur {
    background: rgba(245,245,245,0.75);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
}

//interior custom scss
body.interior {
    section[id*="interiorBanner"] {
        height: 530px;
        min-height: 250px;
        @media screen and (max-width: 1600px) {
            & {
                height: 40vw;
            }
        }
    }
    &.page-type-3 {
        .card {
            padding: 60px 55px;
            background: #1A936F;
            p.h3 {
                text-transform: uppercase;
                color: #fff;
            }
            [id*="HeaderInfoDiv"] {
                font-size: 18px;
                font-weight: 300;
                color: #fff;
                line-height: 30px;
            
            }
            label {
                display: none;
            }
        }
    }
    &.page-type-26 {
        .page-content .container {
            max-width: 960px;
        }
    }
}


    /* Shape divider layer */
    .shape-divider-wrap {
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 150px;
        z-index: 3;
        transform: translateZ(0);
      }
      .post-area.span_9 .shape-divider-wrap {
        overflow: hidden;
      }
      .shape-divider-wrap[data-position="top"] {
        top: -1px;
        bottom: auto;
      }
      .shape-divider-wrap[data-position="top"] {
        transform: rotate(180deg) 
      }
      .shape-divider-wrap[data-front="true"] {
        z-index: 50;
      }
      
      @media only screen and (min-width: 1000px) {
          .shape-divider-wrap[data-style="curve"] .shape-divider {
            filter: blur(0.5px);
            transform: scale(1.03);
          }
      }
      
      .shape-divider-wrap[data-style="waves_opacity"] svg path:first-child {
        opacity: 0.6;
      }
      @media only screen and (min-width: 1000px) {
        .shape-divider-wrap[data-style="clouds"] .shape-divider {
          min-width: 1700px;
        }
      }
      @media only screen and (max-width: 999px) {
        .shape-divider-wrap[data-style="clouds"] .shape-divider {
          min-width: 800px;
        }
      }
      @media only screen and (max-width: 690px) {
        .shape-divider-wrap[data-style="clouds"] .shape-divider {
          min-width: 690px;
        }
      }
      .shape-divider-wrap[data-style="fan"] svg {
        width: 102%;
        left: -1%;
      }
      .shape-divider-wrap[data-style="fan"] svg polygon:nth-child(2) {
        opacity: 0.15;
      }
      .shape-divider-wrap[data-style="fan"] svg rect {
        opacity: 0.3;
      }
      .shape-divider-wrap[data-style="mountains"] svg path:first-child {
        opacity: 0.1;
      }
      .shape-divider-wrap[data-style="mountains"] svg path:nth-child(2) {
        opacity: 0.12;
      }
      .shape-divider-wrap[data-style="mountains"] svg path:nth-child(3) {
        opacity: 0.18;
      }
      .shape-divider-wrap[data-style="mountains"] svg path:nth-child(4) {
        opacity: 0.33;
      }
      .shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(1), .shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(1) {
        opacity: 0.15;
      }
      .shape-divider-wrap[data-style="curve_opacity"] svg path:nth-child(2), .shape-divider-wrap[data-style="waves_opacity_alt"] svg path:nth-child(2) {
        opacity: 0.3;
      }
      .shape-divider {
        width: 100%;
        left: 0;
        bottom: -1px;
        height: 100%;
        position: absolute;
      }
      .shape-divider-wrap.no-color .shape-divider {
        fill: #fff;
      }
      @media only screen and (max-width: 999px) {
        .shape-divider-wrap:not([data-using-percent-val="true"]) .shape-divider {
          height: 75%;
        }
        .shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"]) .shape-divider {
          height: 55%;
        }
      }
      @media only screen and (max-width: 690px) {
        .shape-divider-wrap:not([data-using-percent-val="true"]) .shape-divider {
          height: 33%;
        }
        .shape-divider-wrap[data-style="clouds"]:not([data-using-percent-val="true"]) .shape-divider {
          height: 33%;
        }
        
        .shape-divider-wrap[data-position=top] {
          top: 0px !important;
        }

      }
      
      .row-bg.using-bg-color {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          -webkit-background-size: cover;
      }

      .shape-divider-wrap[data-position=top] {
        top: -31px;
    }
    @media screen and (max-width: 999px) {
        .shape-divider-wrap[data-position=top] {
            top: -20px;
        }
    }

#to-top{
    display:block;
    position:fixed;
    text-align:center;
    line-height:12px;
    right:17px;
    bottom:-30px;
    color:#fff;
    cursor:pointer;
    border-radius: 100%;
    z-index:9994;
    height:29px;
    width:29px;
    background-color:rgba(0,0,0,0.25);
    background-repeat:no-repeat;
    background-position:center;
    transition:background-color 0.1s linear;
    -webkit-transition:background-color 0.1s linear;
    &:hover {
      background-color: $primary;
    }
    &:after {
      background-color: $primary !important;
    }
}

#to-top{
  transition:box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.1s linear;
  -webkit-transition:-webkit-box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.1s linear;
  background-color:rgba(0,0,0,0.25) 
}

#to-top:hover, 
#to-top.dark:hover{
  transition:box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.05s linear 0.25s;
  -webkit-transition:-webkit-box-shadow 0.3s cubic-bezier(.55,0,.1,1),background-color 0.05s linear 0.25s;
  box-shadow:1px 2px 3px rgba(0,0,0,0.16);
  background-color:transparent!important 
}
#to-top:after, 
#to-top:before{
    display:block;
    content:' ';
    height:100%;
    width:100%;
    position:absolute;
    top:0;
    left:0;
    z-index:1;
    background-color:#000;
    transform:scale(0);
    -webkit-transform:scale(0);
    transition:all 0.3s cubic-bezier(.55,0,.1,1);
    -webkit-transition:all 0.3s cubic-bezier(.55,0,.1,1);
    border-radius:100px;
    -webkit-border-radius:100px
}
#to-top:before{
  background-color:rgba(255,255,255,0.25);
  transform:scale(1);
  -webkit-transform:scale(1);
  transition:all 0.5s cubic-bezier(0.165,0.84,0.44,1);
  -webkit-transition:all 0.5s cubic-bezier(0.165,0.84,0.44,1);
  opacity:0;
  z-index:2
}

#to-top:hover:after{
  transform:scale(1);
  -webkit-transform:scale(1);
}
#to-top{
  overflow:hidden
}

#to-top i.fa-angle-up.top-icon, 
#to-top i.fa-angle-up{
  -webkit-transform:translate(0,0px);
  transform:translate(0,0px);
  transition:transform 0.2s ease;
  -webkit-transition:transform 0.2s ease;
}

#to-top:hover i.fa-angle-up.top-icon, 
#to-top:hover i.fa-angle-up, 
#to-top.hovered i.fa-angle-up.top-icon, 
#to-top.hovered i.fa-angle-up{
  -webkit-transform:translate(0,-29px);
  transform:translate(0,-29px)
}

#to-top:active:before{
  opacity:1
}

#to-top i{
  line-height:29px;
  width:29px;
  height:29px;
  font-size:14px;
  top:0;
  left:0;
  text-align:center;
  position:relative;
  z-index:10;
  background-color:transparent 
}
#to-top:hover, 
#to-top.dark:hover{
  background-color:#000
}
#to-top.dark{
  background-color:rgba(0,0,0,0.87)
}

      
/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }