section.hero-section {
    min-height: 872px;

    .company-name {
        position: absolute;
        left: 0px;
        right: 0;
        top: -100px;
    }
    .tag-wrap {
        padding: 0 45px;
    }
    .divider-mod {
        border: 2px solid #88D498;
        width: 200px;
        text-align: center;
        margin: 0 auto 35px;
    }
    .tagline {
        width: auto !important;
    }
    .colored-bg1 {
        position: absolute;
        display: block;
        width: 514px;
        height: 700px;
        background-color: #C6DABF;
        left: 17%;
        top: 77px;
    }
    .mod-wrap {
        margin-top: 5px;
        z-index: 9999;
        left: 4%;
        height: 700px;
        width: 514px;
    }
    .mod, .wrap {
        min-height: 872px;

        .display-1 {
            text-transform: lowercase;
            letter-spacing: 0;
            font-size: 70px;
            line-height: 80px;
            font-weight: 400;
            color: #1A936F;
        }

        .star-wrap {
            width: 25px;
            height: 24px;
            margin: 15px auto 0;
        }

        .logo {
            margin: 0 auto 35px;
        }
    }
    p {
        font-weight: 400;
        letter-spacing: 0;
        margin-bottom: 30px;
    }
    // @media screen and (max-width: 1600px) {
    //     & {
    //         height: 750px;
    //     }
    //     .mod, .wrap {
    //         height: 750px;
    //     }
    // }
    @media screen and (max-width: 998px) {
        & {
            height: auto;
        }
        .mod, .wrap {
            height: auto;
            padding-bottom: 85px;
        }
    }
}